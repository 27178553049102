import { Box, Typography, useTheme } from '@mui/material';
import TimeOff from 'public/assets/icons/time-off.svg';
import Tooltip from '~components/tooltip';
import { busyArtist } from '~config/constants/texts';
function TakingTimeOff({
  sx
}: any) {
  const {
    palette: {
      primary,
      background
    }
  }: any = useTheme();
  return <Tooltip message={busyArtist} data-sentry-element="Tooltip" data-sentry-component="TakingTimeOff" data-sentry-source-file="TakingTimeOff.tsx">
            <Box sx={{
      position: 'absolute',
      top: 24,
      left: 24,
      borderRadius: 16,
      border: '0px solid red',
      px: '12px',
      py: '4px',
      background: background?.manish + "88",
      display: 'flex',
      ...sx
    }} data-sentry-element="Box" data-sentry-source-file="TakingTimeOff.tsx">
                <TimeOff data-sentry-element="TimeOff" data-sentry-source-file="TakingTimeOff.tsx" />
                <Typography variant='subtitle1' sx={{
        color: primary.contrastText,
        fontWeight: 100,
        ml: '8px'
      }} data-sentry-element="Typography" data-sentry-source-file="TakingTimeOff.tsx">
                    Taking Time Off
                </Typography>
            </Box>
        </Tooltip>;
}
export default TakingTimeOff;