import { Box, Card, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import ArrowRight from 'public/assets/icons/arrow-right.svg';
import PlayBtnBox from 'public/assets/icons/play-btn-box.svg';
import StopBtnBox from 'public/assets/icons/stop-btn-box.svg';
import { useState } from 'react';
import TakingTimeOff from '~components/taking-time-off';
import { artistPath } from '~config/constants/paths';
import { centerFace } from '~helper/index';
import useResponsive from '~hooks/useResponsive';
import AudioPlayer from '~screens/shared/audio-player';
type CardProps = {
  _id?: string;
  pfpPath: string;
  firstName: string;
  lastName: string;
  stageName: string;
  musicTraits?: any;
  artistSong: any;
  margin?: string;
  height?: any;
  xs?: number;
  openForWork: boolean;
  containerSx?: any;
};
function SingersCard({
  _id,
  height,
  pfpPath,
  firstName,
  lastName,
  stageName,
  artistSong,
  musicTraits,
  openForWork,
  containerSx
}: CardProps) {
  const {
    palette: {
      primary,
      common,
      text,
      background
    }
  }: any = useTheme();
  const isMobile = useResponsive('down', 'md');
  const {
    push
  } = useRouter();
  const [togglePlay, setTogglePlay] = useState(false);
  const handlePlayPause = (e: any) => {
    e?.stopPropagation?.();
    setTogglePlay(prev => !prev);
  };
  const handleClickCard = (e: any) => {
    e.stopPropagation();
    push(`${artistPath}${_id}/`);
  };
  const renderMusicTraits = musicTraits?.map?.(({
    _id,
    name
  }: any, index: number) => <Typography key={index} sx={{
    width: 'fit-content',
    display: 'inline-block',
    backgroundColor: primary.main,
    borderRadius: 3,
    padding: '2px 5px',
    color: common.white,
    mr: 1,
    mt: 1
  }}>
      {name}
    </Typography>);
  return <Box sx={{
    my: {
      md: 2,
      xs: 1
    },
    height: {
      md: height ? height : '600px',
      xs: '480px'
    },
    ...containerSx
  }} data-sentry-element="Box" data-sentry-component="SingersCard" data-sentry-source-file="SingersCard.tsx">
      <Card onClick={handleClickCard} sx={{
      position: 'relative',
      cursor: 'pointer',
      height: {
        md: height ? height : '600px',
        xs: '480px'
      },
      width: {
        md: '450px',
        xs: '343px'
      },
      backgroundImage: `url(${centerFace(pfpPath, isMobile ? 480 : height ? height?.replace?.('px', '') : 600, isMobile ? 343 : 450)})`,
      backgroundSize: 'cover',
      borderRadius: '30px',
      border: '0px solid red'
    }} data-sentry-element="Card" data-sentry-source-file="SingersCard.tsx">
        <Box display="flex" flexDirection="column" position="absolute" bottom="0%" paddingLeft={4} paddingRight={2} paddingBottom={3} sx={{
        width: '100%',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) -1.23%, #000000 80%)',
        border: '0px solid red'
      }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
          <Box justifyContent="flex-start" data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
            <Typography variant="h3" sx={{
            border: '0px solid red',
            mt: 4,
            color: common.white
          }} data-sentry-element="Typography" data-sentry-source-file="SingersCard.tsx">
              {stageName || `${firstName} ${lastName}`}
            </Typography>
          </Box>
          <Box sx={{
          mt: firstName ? undefined : 6.8,
          display: 'flex',
          flex: 1,
          border: '0px solid red',
          alignItems: 'flex-start'
        }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
            <Box display="flex" justifyContent="space-between" flexDirection="row" sx={{
            border: '0px solid red',
            width: '100%'
          }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
              <Box sx={{
              width: '76%',
              display: 'flex',
              flexDirection: 'column',
              border: '0px solid red'
            }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
                <Box sx={{
                border: '0px solid red'
              }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
                  <Box sx={{
                  display: 'inline-block',
                  visibility: renderMusicTraits?.length > 0 ? 'visible' : 'hidden',
                  width: '100%',
                  border: '0px solid red',
                  pb: 1
                }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
                    {renderMusicTraits}
                  </Box>
                </Box>
                <Box sx={{
                border: '0px solid red',
                flex: 1,
                display: 'flex',
                alignItems: 'flex-end'
              }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
                  <Box display="flex" alignItems="center" sx={{
                  fontSize: '14px',
                  color: text.off,
                  border: '0px solid red',
                  width: '100%'
                }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
                    MORE
                    <ArrowRight style={{
                    marginLeft: 10
                  }} width={25} height={13} data-sentry-element="ArrowRight" data-sentry-source-file="SingersCard.tsx" />
                    <Box onClick={(e: any) => e.stopPropagation()} sx={{
                    border: '0px solid red',
                    width: '300px',
                    ml: 1,
                    visibility: togglePlay ? 'visible' : 'hidden'
                  }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
                      <AudioPlayer onlySeek={true} audioUrl={artistSong?.audioFile?.path} _id={artistSong?.audioFile?._id} togglePlay={togglePlay} setTogglePlay={setTogglePlay} data-sentry-element="AudioPlayer" data-sentry-source-file="SingersCard.tsx" />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box onClick={handlePlayPause} sx={{
              border: '0px solid red',
              visibility: artistSong?.audioFile?.path ? 'visible' : 'hidden',
              display: 'flex',
              alignItems: {
                md: 'unset',
                xs: 'flex-end'
              }
            }} data-sentry-element="Box" data-sentry-source-file="SingersCard.tsx">
                {togglePlay ? <StopBtnBox style={{
                border: '0px solid red',
                cursor: 'pointer',
                marginTop: 30
              }} width={isMobile ? '61px' : '95px'} height={isMobile ? '61px' : '95px'} /> : <PlayBtnBox style={{
                border: '0px solid red',
                cursor: 'pointer',
                marginTop: 30
              }} width={isMobile ? '61px' : '95px'} height={isMobile ? '61px' : '95px'} />}
              </Box>
            </Box>
          </Box>
        </Box>
        {!openForWork && <TakingTimeOff />}
      </Card>
    </Box>;
}
export default SingersCard;